<template>
	<v-btn icon class="toggle" @click="toggle"
		><v-icon>{{ icon }}</v-icon></v-btn
	>
</template>

<style lang="less">
	.toggle {
		position: absolute;
		left: 10px;
		top: 5px;
		z-index: 100;
	}
</style>
<script>
export default {
	name: "ToggleItems",
	props: {
		value: { type: Array },
		selected: { type: Array },
	},
	computed: {
		count() {
			return this.selected.length;
		},
		all() {
			let selected = this.selected;
			let value = this.value;
			return value.every((v) => selected.includes(v));
		},
		some() {
			let selected = this.selected;
			let value = this.value;
			let match = selected.find((v) => value.includes(v));
			return match ? true : false;
		},
		none() {
			return !this.some;
		},
		icon() {
			if (this.none) {
				return "mdi-checkbox-blank-outline";
			}
			if (this.all) {
				return "mdi-checkbox-marked";
			}
			return "mdi-checkbox-intermediate";
		},
	},
	methods: {
		toggle() {
			this.$emit("toggle", this.value);
		},
	},
};
</script>
