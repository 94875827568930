<template>
    <v-btn icon @click="save" outlined><v-icon>mdi-download</v-icon></v-btn>
</template>

<script type="text/javascript">
	export default {
        name: "SaveImage", 
		props: {
            id: {},
			filename: {type: String, default: "Image.png"}
		},
		data: () => {
            return {
			}
		},
		methods: {
            save() {
				var a = document.createElement('a');
				var canvas = document.querySelector(`#${this.id} canvas`);
				var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");  
				a.href = image;
				a.download = this.filename;
				document.body.appendChild(a);
				a.click();
			},
		}
		// created(){
            
		// }

	}
// </script>"