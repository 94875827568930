<template>
	<v-navigation-drawer
		v-model="drawer"
		:mini-variant="mini"
		permanent
		app
		right
		style="z-index: 101"
		width="350px"
	>
		<template v-if="!mini">
			<v-list>
				<select-series
					v-for="(s, i) in series"
					:key="s.id"
					:value="s"
					@input="(v) => updateSeries(i, v)"
				>
				</select-series>
			</v-list>
			<v-divider></v-divider>
			<v-card flat>
				<v-card-text>
					<v-radio-group
						@change="(v) => $emit('input', 'seriesAggregation', v)"
						:value="seriesAggregation"
						color="primary"
						hide-details
						label="Aggregate data by"
					>
						<v-radio
							dense
							class="small-text"
							v-for="agg in aggregationOptions"
							:key="agg"
							:value="agg"
							:label="$t(`results.${agg}`)"
						></v-radio>
					</v-radio-group>
				</v-card-text>
			</v-card>
			<v-divider></v-divider>
			<data-selection-panel
				:value="selectedQuestions"
				@input="(v) => $emit('input', 'selectedQuestions', v)"
			></data-selection-panel>
			<v-divider></v-divider>
			<v-card flat>
				<v-card-text>
					<v-item-group
						:value="visualisationType"
						@change="(v) => $emit('input', 'visualisationType', v)"
						class="tile-buttons"
					>
						<v-item v-slot="{ active, toggle }" value="chart">
							<v-avatar
								tile
								:color="active ? 'green' : 'secondary'"
								@click="toggle"
								class="mr-1"
							>
								<v-icon :color="active ? 'white' : 'green'"
									>mdi-chart-bar</v-icon
								>
							</v-avatar>
						</v-item>
						<v-item v-slot="{ active, toggle }" value="table">
							<v-avatar
								tile
								:color="active ? 'green' : 'secondary'"
								@click="toggle"
								class="mr-1"
							>
								<v-icon :color="active ? 'white' : 'green'"
									>mdi-table</v-icon
								>
							</v-avatar>
						</v-item>
					</v-item-group>
				</v-card-text>
			</v-card>
			<v-divider></v-divider>
			<v-card flat>
				<v-card-text>
					<chart-types
						v-if="visualisationType == 'chart'"
						:value="chartType"
						@change="(v) => $emit('input', 'chartType', v)"
					></chart-types>
				</v-card-text>
			</v-card>
		</template>
		<v-list dense> </v-list>
		<template v-slot:append>
			<v-divider class="mt-1 mb-3"></v-divider>
			<v-list-item>
				<save-image
					v-if="visualisationType == 'chart'"
					id="chart"
				></save-image>
				<export
					v-else
					icon
					outlined
					:columns="tableColumns"
					:rows="tableRows"
					name="vis"
				></export>
				<report-builder
					v-if="!mini"
					:chartType="chartType"
					:chartData="chartData"
					:chartOptions="chartOptions"
					:visualisationType="visualisationType"
					:tableRows="tableRows"
					:tableColumns="tableColumns"
				></report-builder>
			</v-list-item>
			<v-list dense class="pl-1">
				<nav-item
					@click="mini = !mini"
					:text="$t('buttons.collapse')"
					:icon="mini ? 'mdi-chevron-left' : 'mdi-chevron-right'"
				></nav-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>
<style lang="less">
.small-text .v-label {
	font-size: 0.8em !important;
}
</style>
<script>
import NavItem from "@c/navigation/Item";
import ChartTypes from "@c/results/analysis/ChartTypes";
import DataSelectionPanel from "@c/results/analysis/DataSelectionPanel";
import ReportBuilder from "@c/results/report-builder/ReportBuilder";
import SaveImage from "@c/downloads/SaveImage";
import Export from "@c/downloads/Export";
import SelectSeries from "@c/results/analysis/SelectSeries";
export default {
	name: "AnalysisSidebar",
	props: {
		series: { type: Array },
		seriesAggregation: { type: String },
		selectedQuestions: { type: Array },
		visualisationType: { type: String },
		chartType: { type: String },
		chartData: { type: Object },
		chartOptions: { type: Object },
		tableColumns: { type: Array },
		tableRows: { type: Array },
	},
	data() {
		return {
			drawer: true,
			mini: false,
			aggregationOptions: ["average", "theme", "section", "question"],
		};
	},
	computed: {},
	components: {
		ChartTypes,
		DataSelectionPanel,
		Export,
		NavItem,
		ReportBuilder,
		SaveImage,
		SelectSeries,
	},
	methods: {
		updateSeries(index, value) {
			let series = [...this.series];
			series[index] = value;
			this.$emit("input", "series", series);
		},
	},
};
</script>
