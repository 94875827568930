<template>
	<v-list-item>
        <v-list-item-icon>
            <select-color :value="value.color" @input="changeColor"></select-color>
        </v-list-item-icon>
        <v-list-item-content>
            {{value.name}}
        </v-list-item-content>
	</v-list-item>
</template>
<script>
import SelectColor from "@c/results/analysis/SelectColor";
export default {
	name: "SelectSeries",
	props: {
		value: { type: Object },
	},
	components: {
		SelectColor,
	},
	methods: {
		changeColor(v){
			let value = {...this.value}
			value.color = v;
			this.$emit("input", value)
		}
	}
};
</script>
