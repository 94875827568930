<template>
	<div style="position: relative">
		<toggle-items
			:value="questions"
			:selected="selected"
			@toggle="toggle"
		></toggle-items>

		<v-list-group>
			<template v-slot:activator>
				<v-list-item-content>
					<v-list-item-subtitle>{{ section.name }}</v-list-item-subtitle>
				</v-list-item-content>
			</template>
			<template v-slot:prependIcon>
				&nbsp;
			</template>
			<select-question
				@toggle="toggle"
				v-for="question in questions"
				:key="question"
				:selected="selected"
				:id="question"
			></select-question>
		</v-list-group>
	</div>
</template>

<script>
import ToggleItems from "@c/results/analysis/ToggleItems";
import SelectQuestion from "@c/results/analysis/SelectQuestion";
export default {
	name: "Section",
	props: {
		id: { type: String },
		selected: { type: Array, default: () => [] },
	},
	data: () => {
		return {
			active: false,
		};
	},
	computed: {
		section() {
			return this.$store.state.sections.data[this.id];
		},
		questionTemplates() {
			return this.$store.state.questionTemplates.data;
		},
		questions() {
			const self = this;
			let questions = this.$store.state.questions.data;
			return self.section.questions.filter((q) => {
				let question = questions[q];
				if (question) {
					let question_template_id = question.question_template;
					if (question_template_id) {
						let question_template =
							self.questionTemplates[question_template_id] || {};
						if (question_template.scored) {
							return true;
						}
					}
				}
			});
		},
	},
	components: {
		SelectQuestion,
		ToggleItems,
	},
	methods: {
		toggle(value) {
			this.$emit("toggle", value);
		},
	},
};
</script>
