    
<template>
    <v-menu>
        <template v-slot:activator="{on}">
            <v-avatar tile :color="value" v-on="on">
				&nbsp;				
			</v-avatar>
        </template>
        <v-color-picker
           @input="v => $emit('input', v.hex || v)"
           :value="value"
        ></v-color-picker>
    </v-menu>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@c/"
	export default {
		name: "SelectColour", 
		props: {
			value: {}
		},
	}
// </script>"