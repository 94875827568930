<template>
	<v-list dense expand>
		<select-theme
			v-for="theme in themes"
			:key="theme.id"
			:theme="theme"
			:selected="value"
			@toggle="toggle"
		></select-theme>
	</v-list>
</template>

<style lang="less">
.v-list-item--dense .v-list-item__icon,
.v-list--dense .v-list-item .v-list-item__icon {
	margin-top: 2px;
}
</style>

<script type="text/javascript">
import SelectTheme from "@c/results/analysis/SelectTheme";
export default {
	name: "DataSelectionPanel",
	props: {
		value: { type: Array },
	},
	data: () => {
		return {};
	},
	computed: {
		themes() {
			let sections = Object.values(this.$store.state.sections.data);
			return this.mwutils
				.sortByKey(this.$store.getters["themes/options"], "position")
				.filter((theme) => {
					return sections.find((section) => section.theme == theme.id);
				});
		},
	},
	methods: {
		toggle(questions){
			const self = this;
			let value = [...self.value];
			if( questions.every( (q) => value.includes(q) ) ){
				value = self.value.filter( v => !questions.includes(v) );
				this.$emit("input", value )
			}
			else{
				questions.forEach( q => {
					if( !value.includes(q) ){
						value.push(q);
					}
				} )
				this.$emit("input", value )
			}
		}
	},
	components: {
		SelectTheme,
	},
};
//
</script>
"
