<template>
	<span>
		<v-btn icon class="ml-2" outlined @click="snackbar = true">
			<v-icon>mdi-file-pdf-box</v-icon>
		</v-btn>
		<v-snackbar v-model="snackbar" :timeout="-1" light rounded multi-line>
			<v-btn
				small
				style="position: absolute; right: 10px"
				icon
				@click="snackbar = false"
				><v-icon small>mdi-close</v-icon></v-btn
			>
			<v-select
				class="mt-4"
				v-model="report"
				label="Select Report"
				:items="reportItems"
			></v-select>
			<v-badge
				v-if="report"
				color="success"
				overlap
				bordered
				icon="mdi-plus"
			>
				<v-btn @click="newViz" icon outlined
					><v-icon>mdi-chart-bar</v-icon></v-btn
				>
			</v-badge>
			<v-btn
				v-if="report"
				icon
				outlined
				class="ml-3"
				link
				target="_blank"
				:to="`/report/${report}`"
				><v-icon>mdi-open-in-new</v-icon></v-btn
			>
		</v-snackbar>
	</span>
</template>

<style lang="less"></style>
<script>
// import MwDialog from "@c/ui/MwDialog";
import { arrayUnion } from "vuex-easy-firestore";

export default {
	name: "ReportBuilder",
	props: {
		chartType: { type: String },
		visualisationType: { type: String },
		tableRows: { type: Array },
		chartData: { type: [Array, Object] },
		tableColumns: { type: Array },
	},
	data: () => {
		return {
			report: false,
			dialog: false,
			snackbar: false,
		};
	},
	computed: {
		reports() {
			return this.$store.state.reports.data;
		},
		reportItems() {
			let reports = this.mwutils.itemsArray(this.reports);
			reports.push({ value: "new", text: `New Report...` });
			return reports;
		},
	},
	components: {
		// MwDialog,
	},
	watch: {
		snackbar(value) {
			if (!value) {
				this.report = false;
			}
		},
		report(value) {
			if (value == "new") {
				this.newReport();
			}
		},
	},
	methods: {
		newReport() {
			const self = this;
			let n = Object.keys(self.reports).length + 1;
			self.$store
				.dispatch("reports/insert", {
					name: `Report ${n}`,
				})
				.then((res) => {
					self.report = res;
				});
		},
		newViz() {
			const self = this;
			let viz = {
				type: self.visualisationType,
			};
			if (viz.type == "chart") {
				(viz.chart_data = self.chartData),
					(viz.chart_type = self.chartType);
			}
			if (viz.type == "table") {
				(viz.table_columns = self.tableColumns),
					(viz.table_rows = self.tableRows);
			}
			self.$store.dispatch("visualisations/insert", viz).then((res) => {
				self.addViz(res);
			});
		},
		addViz(id) {
			const self = this;
			self.$store.dispatch("reports/patch", {
				id: self.report,
				visualisations: arrayUnion(id),
			});
		},
	},
	mounted() {
		this.$store.dispatch("reports/fetchMine");
	},
};
</script>
