<template>
	<v-sheet>
		<render-content id="scores"> </render-content>
		<v-data-table :headers="headers" :items="items"></v-data-table>
		<export :columns="headers" :rows="items" name="dma-data" text="Download"></export>
	</v-sheet>
</template>

<style lang="less"></style>

<script>
import Export from "@c/downloads/Export";
import RenderContent from "@c/ui/RenderContent.vue";
export default {
	name: "Overview",
	components: {
		RenderContent,
		Export,
	},
	data: () => {
		return {};
	},
	computed: {
		mySections() {
			return this.$store.getters.mySections;
		},
		categoryOptions() {
			return this.$store.state.categoryOptions.data;
		},
		headers() {
			return [
				{ text: "Section", value: "section" },
				{ text: "Theme", value: "theme" },
				{ text: "Score", value: "score" },
			];
		},
		order() {
			return this.$store.getters.order;
		},
		responses() {
			return this.$store.state.sectionResponses.data;
		},
		sections() {
			return this.$store.state.sections.data;
		},
		results() {
			const self = this;
			return this.order
				.filter((item) => self.responses[item.response])
				.flatMap((item) => {
					let response = self.responses[item.response];
					return this.mwsurveyutils.processResponse(response);
				})
				.filter((exists) => exists);
		},
		items() {
			const self = this;
			return self.mySections
				.filter((s) => self.sections[s])
				.map((section) => {
					let results = self.results.filter((r) => r.section == section);
					results = results.filter((r) => r.answer);
					let scores = results.map((r) => r.score);	
					scores = scores.filter( a => a !== null );
					let score = Math.floor(self.mwutils.average(scores));
					let theme = self.categoryOptions[self.sections[section].theme];
					return {
						section: self.sections[section].name,
						theme: theme ? theme.name : "",
						score,
					};
				});
		},
	},
};
</script>
