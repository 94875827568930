<template>
    <v-list-item>
        <v-list-item-icon>
           <toggle-items :value="[id]" :selected="selected" @toggle="$emit('toggle', [id])"></toggle-items>
        </v-list-item-icon>
        <v-list-item-content>
            <small class="font-weight-light">{{ questionText }}</small>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import ToggleItems from "@c/results/analysis/ToggleItems";
export default {
	name: "Question",
	props: {
		id: { type: String },
        selected: {type: Array, default: () => [] }
	},
	data: () => {
		return {
		};
	},
	computed: {
		questionText() {
			let qt = this.$store.getters["questions/getQuestionTexts"](this.id);
            return qt ? qt.string : "";
		},
	},
    components: {
        ToggleItems
    }
};
</script>
