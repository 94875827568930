<template>
	<v-sheet flat>
		<div>
			<overview></overview>
		</div>
	</v-sheet>
</template>

<style lang="less">
</style>

<script>
import Overview from '@c/results/Overview.vue';
export default {
	name: "Results",
	components: {
		Overview
	},
	data: () => {
		return {
		};
	},
	computed: {
	},
	methods: {		
	},
	watch: {
	},
	mounted() {
	}
};
</script>
