<template>
	<v-item-group v-model="type" class="tile-buttons">
		<v-item
			v-slot="{ active, toggle }"
			v-for="type in chartTypes"
			:key="type.name"
			:value="type.name"
		>
			<v-avatar
				tile
				:color="active ? 'green' : 'secondary'"
				@click="toggle"
				class="mr-1"
			>
				<v-icon :color="active ? 'white' : 'green'">{{ type.icon }}</v-icon>
			</v-avatar>
		</v-item>
	</v-item-group>
</template>

<style lang="less">
.chartWrapper {
	width: 100%;
	position: relative;
}
</style>

<script type="text/javascript">
// Selects the chart type - required toggle button
export default {
	name: "ChartType",
	data: () => {
		return {
			type: "BarChart",
		};
	},
	computed: {
		chartTypes() {
			return [
				{
					name: "BarChart",
					icon: "mdi-chart-bar",
					title: this.$t("results.bar_chart"),
				},
				{
					name: "LineChart",
					icon: "mdi-chart-line",
					title: this.$t("results.line_chart"),
				},
				{
					name: "AreaChart",
					icon: "mdi-chart-areaspline-variant",
					title: this.$t("results.area_chart"),
				},
				{
					name: "HBarChart",
					icon: "mdi-chart-gantt",
					title: this.$t("results.hbar_chart"),
				},
			];
		},
	},
	watch: {
		type(v) {
			this.$emit("change", v);
		},
	},
};
//
</script>
"
