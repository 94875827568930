<template>
	<div style="position: relative">
		<toggle-items
			:value="questions"
			:selected="selected"
			@toggle="toggle"
		></toggle-items>
		<v-list-group class="analysis-list" v-model="active">
			<template v-slot:activator>
				<v-list-item-content>
					<v-list-item-title>
						<span>{{ theme.name }}</span>
						<v-badge
							class="mr-3 ml-5"
							:content="sections.length"
							:color="theme.color"
						></v-badge>
					</v-list-item-title>
				</v-list-item-content>
			</template>
			<template v-slot:prependIcon>
				&nbsp;
			</template>
			<select-section
				v-for="section in sectionIds"
				:key="section"
				:id="section"
				:selected="selected"
				@toggle="toggle"
			></select-section>
		</v-list-group>
	</div>
</template>

<style lang="less" scoped>
.analysis-list {
	.v-application--is-ltr .v-list-item__action:first-child,
	.v-application--is-ltr .v-list-item__icon:first-child {
		margin-right: 12px;
	}
	&.v-list-group--active {
		// border-left: 3px solid green;
	}
}
</style>
<script>
import ToggleItems from "@c/results/analysis/ToggleItems";
import SelectSection from "@c/results/analysis/SelectSection";
export default {
	name: "Theme",
	props: {
		theme: { type: Object },
		selected: { type: Array, default: () => [] },
	},
	data: () => {
		return {
			active: false,
		};
	},
	computed: {
		sections() {
			let sections = this.$store.state.sections.data;
			let theme = this.theme.id;
			return Object.values(sections).filter(
				(section) => section.theme == theme
			);
		},
		sectionIds() {
			return this.sections
				.filter((section) => section.questions)
				.map((section) => section.id);
		},
		questionTemplates() {
			return this.$store.state.questionTemplates.data;
		},
		questions() {
			const self = this;
			let questions = this.$store.state.questions.data;
			return self.sections
				.flatMap((section) => section.questions)
				.filter((q) => {
					let question = questions[q];
					if (question) {
						let question_template_id = question.question_template;
						if (question_template_id) {
							let question_template =
								self.questionTemplates[question_template_id] || {};
							if (question_template.scored) {
								return true;
							}
						}
					}
				});
		},
	},
	components: {
		SelectSection,
		ToggleItems,
	},
	methods: {
		toggle(value) {
			this.$emit("toggle", value);
		},
	},
};
</script>
